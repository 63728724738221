import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue";
import _imports_0 from './images/logo2.png';
import _imports_1 from './images/logo.png';
import _imports_2 from './images/banner.png';
import _imports_3 from './images/about.png';
import _imports_4 from './images/edge1.png';
import _imports_5 from './images/edge2.png';
import _imports_6 from './images/edge3.png';
import _imports_7 from './images/expert1_1.png';
import _imports_8 from './images/expert1_2.png';
import _imports_9 from './images/expert2_1.png';
import _imports_10 from './images/expert2_2.png';
import _imports_11 from './images/running.png';
import _imports_12 from './images/customization.png';
import _imports_13 from './images/service.png';
import _imports_14 from './images/system.png';
import _imports_15 from './images/team.png';
import _imports_16 from './images/code_wx.png';
import _imports_17 from './images/code_dy.png';
import _imports_18 from './images/code_xhs.png';
import _imports_19 from './images/address.png';
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "module menu flex"
};
const _hoisted_4 = {
  key: 0,
  class: "logo",
  src: _imports_0,
  alt: ""
};
const _hoisted_5 = {
  key: 1,
  class: "logo",
  src: _imports_1,
  alt: ""
};
const _hoisted_6 = {
  class: "menu_list flex"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  id: "content2",
  class: "bg_gray"
};
const _hoisted_9 = {
  class: "content"
};
const _hoisted_10 = {
  class: "module design"
};
const _hoisted_11 = {
  class: "design_main"
};
const _hoisted_12 = {
  class: "design_nav flex"
};
const _hoisted_13 = ["onClick"];
const _hoisted_14 = {
  class: "design_content"
};
const _hoisted_15 = {
  class: "design_module"
};
const _hoisted_16 = {
  class: "design_module"
};
const _hoisted_17 = {
  class: "design_module"
};
const _hoisted_18 = ["src"];
const _hoisted_19 = {
  class: "paging"
};
const _hoisted_20 = ["onClick"];
const _hoisted_21 = {
  id: "content3",
  class: "content"
};
const _hoisted_22 = {
  class: "module design"
};
const _hoisted_23 = {
  class: "design_main"
};
const _hoisted_24 = {
  class: "design_study_nav flex"
};
const _hoisted_25 = ["onClick"];
const _hoisted_26 = {
  class: "design_content"
};
const _hoisted_27 = {
  class: "design_module"
};
const _hoisted_28 = {
  class: "design_module"
};
const _hoisted_29 = {
  class: "design_module"
};
const _hoisted_30 = {
  class: "design_module"
};
const _hoisted_31 = ["src"];
const _hoisted_32 = {
  class: "paging"
};
const _hoisted_33 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    ref: "mainDom",
    class: "main",
    onScroll: _cache[0] || (_cache[0] = (...args) => $setup.handleScroll && $setup.handleScroll(...args))
  }, [_createElementVNode("div", {
    class: _normalizeClass([_ctx.isScroll ? 'header_scroll' : '', "header"])
  }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_ctx.isScroll ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : (_openBlock(), _createElementBlock("img", _hoisted_5)), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "menu_item",
      key: index,
      onClick: $event => $setup.handleMenu(index)
    }, _toDisplayString(item), 9, _hoisted_7);
  }), 128))])])])], 2), _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"banner\" data-v-4b0afe6c><img class=\"img\" src=\"" + _imports_2 + "\" alt=\"\" data-v-4b0afe6c><div class=\"desc\" data-v-4b0afe6c><div class=\"desc_detail\" data-v-4b0afe6c><p class=\"desc_detail_title\" data-v-4b0afe6c>专业国际教育陪跑，铺就英才未来之路</p><p class=\"desc_detail_text desc_detail_top\" data-v-4b0afe6c> 随着全球化时代的来临，更多学生以及家长希望未来能够登陆国际教育大舞台，但 </p><p class=\"desc_detail_text\" data-v-4b0afe6c>是对于国际教育的路线并非一帆风顺，过程中挑战重重。</p></div></div></div><div id=\"content0\" class=\"content\" data-v-4b0afe6c><div class=\"module anenst\" data-v-4b0afe6c><div class=\"module_title\" data-v-4b0afe6c>关于禾美露</div><div class=\"anenst_main flex\" data-v-4b0afe6c><img src=\"" + _imports_3 + "\" alt=\"\" data-v-4b0afe6c><p data-v-4b0afe6c> 禾美露教育是一家专业致力于国际教育陪跑的机构，为学生和家长提供了条通往国际教育大舞台的清晰路径。通过提供时间管理、专业督导、学业规划、升学策略以及个人能力提升和学术资源拓展等多方面的支持，确保学生在追求国际教育的道路上获得全面的专业指导和规划。 </p></div></div></div><div id=\"content1\" class=\"content\" data-v-4b0afe6c><div class=\"module edge\" data-v-4b0afe6c><div class=\"module_title\" data-v-4b0afe6c>我们的优势</div><div class=\"edge_main flex\" data-v-4b0afe6c><img src=\"" + _imports_4 + "\" alt=\"\" data-v-4b0afe6c><img src=\"" + _imports_5 + "\" alt=\"\" data-v-4b0afe6c><img src=\"" + _imports_6 + "\" alt=\"\" data-v-4b0afe6c></div></div></div>", 3)), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_cache[4] || (_cache[4] = _createElementVNode("div", {
    class: "module_title"
  }, "低龄教育规划", -1)), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.designNav, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass([_ctx.designId === index ? 'design_nav_item_click' : '', "design_nav_item"]),
      key: index,
      onClick: $event => $setup.changeDesign(index)
    }, _toDisplayString(item), 11, _hoisted_13);
  }), 128))]), _createElementVNode("div", _hoisted_14, [_withDirectives(_createElementVNode("div", null, _cache[1] || (_cache[1] = [_createStaticVNode("<p class=\"design_content_title\" data-v-4b0afe6c>菁英智囊专家1V1全流程陪伴</p><p class=\"design_content_desc\" data-v-4b0afe6c> 由业内顶尖专家组成的智囊团，为学生提供专业的规划和全流程的陪跑服务 </p><div class=\"design_content_detail flex\" data-v-4b0afe6c><div class=\"design_content_img_wrap design_content_img_left\" data-v-4b0afe6c><img class=\"design_content_img\" src=\"" + _imports_7 + "\" alt=\"\" data-v-4b0afe6c><img class=\"design_content_img design_content_img2\" src=\"" + _imports_8 + "\" alt=\"\" data-v-4b0afe6c></div><div class=\"design_content_img_wrap design_content_img_right\" data-v-4b0afe6c><img class=\"design_content_img\" src=\"" + _imports_9 + "\" alt=\"\" data-v-4b0afe6c><img class=\"design_content_img design_content_img2\" src=\"" + _imports_10 + "\" alt=\"\" data-v-4b0afe6c></div></div>", 3)]), 512), [[_vShow, _ctx.designId === 0]]), _withDirectives(_createElementVNode("div", _hoisted_15, _cache[2] || (_cache[2] = [_createElementVNode("img", {
    src: _imports_11,
    alt: ""
  }, null, -1)]), 512), [[_vShow, _ctx.designId === 1]]), _withDirectives(_createElementVNode("div", _hoisted_16, _cache[3] || (_cache[3] = [_createElementVNode("img", {
    src: _imports_12,
    alt: ""
  }, null, -1)]), 512), [[_vShow, _ctx.designId === 2]]), _withDirectives(_createElementVNode("div", _hoisted_17, [_createElementVNode("img", {
    src: $setup.getDesignUrl(_ctx.item),
    alt: ""
  }, null, 8, _hoisted_18), _createElementVNode("div", _hoisted_19, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, item => {
    return _createElementVNode("span", {
      class: _normalizeClass([_ctx.designUrl === item ? 'paging_item_click' : '', "paging_item"]),
      key: item,
      onClick: $event => $setup.handleDesignUrl(item)
    }, null, 10, _hoisted_20);
  }), 64))])], 512), [[_vShow, _ctx.designId === 3]])])])])])]), _createElementVNode("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, [_cache[8] || (_cache[8] = _createElementVNode("div", {
    class: "module_title"
  }, "本科留学项目", -1)), _createElementVNode("div", _hoisted_23, [_createElementVNode("div", _hoisted_24, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.studyNav, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass([_ctx.studyId === index ? 'design_nav_item_click' : '', "design_nav_item"]),
      key: index,
      onClick: $event => $setup.changeStudy(index)
    }, _toDisplayString(item), 11, _hoisted_25);
  }), 128))]), _createElementVNode("div", _hoisted_26, [_withDirectives(_createElementVNode("div", _hoisted_27, _cache[5] || (_cache[5] = [_createElementVNode("img", {
    src: _imports_13,
    alt: ""
  }, null, -1)]), 512), [[_vShow, _ctx.studyId === 0]]), _withDirectives(_createElementVNode("div", _hoisted_28, _cache[6] || (_cache[6] = [_createElementVNode("img", {
    src: _imports_14,
    alt: ""
  }, null, -1)]), 512), [[_vShow, _ctx.studyId === 1]]), _withDirectives(_createElementVNode("div", _hoisted_29, _cache[7] || (_cache[7] = [_createElementVNode("img", {
    src: _imports_15,
    alt: ""
  }, null, -1)]), 512), [[_vShow, _ctx.studyId === 2]]), _withDirectives(_createElementVNode("div", _hoisted_30, [_createElementVNode("img", {
    src: $setup.getStudyUrl(),
    alt: ""
  }, null, 8, _hoisted_31), _createElementVNode("div", _hoisted_32, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, item => {
    return _createElementVNode("span", {
      class: _normalizeClass([_ctx.studyUrl === item ? 'paging_item_click' : '', "paging_item"]),
      key: item,
      onClick: $event => $setup.handleStudyUrl(item)
    }, null, 10, _hoisted_33);
  }), 64))])], 512), [[_vShow, _ctx.studyId === 3]])])])])]), _cache[10] || (_cache[10] = _createStaticVNode("<div id=\"content4\" class=\"bg_gray\" data-v-4b0afe6c><div class=\"content\" data-v-4b0afe6c><div class=\"module\" data-v-4b0afe6c><div class=\"module_title\" data-v-4b0afe6c>联系我们</div><div class=\"footer\" data-v-4b0afe6c><div class=\"code_img flex\" data-v-4b0afe6c><img src=\"" + _imports_16 + "\" alt=\"\" data-v-4b0afe6c><img src=\"" + _imports_17 + "\" alt=\"\" data-v-4b0afe6c><img src=\"" + _imports_18 + "\" alt=\"\" data-v-4b0afe6c></div><img class=\"address\" src=\"" + _imports_19 + "\" alt=\"\" data-v-4b0afe6c></div><div class=\"filings\" data-v-4b0afe6c><a href=\"https://beian.miit.gov.cn\" target=\"_blank\" data-v-4b0afe6c>备案/许可证编号为：沪ICP备2024091788号-1</a></div></div></div></div>", 1))], 544)]);
}