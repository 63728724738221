import { reactive, toRefs, onMounted, nextTick, ref } from 'vue';
import { setTitle } from '@/utils/jsUnit.ts';
export default {
  name: 'Home',
  setup() {
    setTitle('禾美露-专业国际教育陪跑，铺就英才未来之路');
    let mainDom = ref({});
    const data = reactive({
      menuId: 0,
      menu: ['关于禾美露', '我们的优势', '低龄教育规划', '本科留学项目', '联系我们'],
      designId: 0,
      designNav: ['菁英智囊专家', '专业陪跑服务', '专属定制化课程', '成功案例'],
      designUrl: 1,
      studyId: 0,
      studyNav: ['服务概要', '全方位服务体系', '专家团队介绍', '学生案例'],
      studyUrl: 1,
      isScroll: false,
      list: {}
    });
    const setFontSize = () => {
      const baseSize = 19.2; // 基准字体大小
      const designWidth = 1920; // 设计稿宽度

      const docEl = document.documentElement;
      let clientWidth = docEl.clientWidth || window.innerWidth;
      if (!clientWidth) return;
      if (clientWidth < 1200) {
        clientWidth = 1200;
      }
      if (clientWidth > 1920) {
        clientWidth = 1920;
      }
      docEl.style.fontSize = `${clientWidth / designWidth * baseSize}px`;
    };

    // 在组件挂载时执行
    onMounted(() => {
      setFontSize();
      window.addEventListener('resize', setFontSize);
    });
    const handleScroll = event => {
      // 获取滚动位置
      const scrollTop = event.target.scrollTop;
      // 处理滚动逻辑
      // console.log('滚动位置:', scrollTop)
      data.isScroll = scrollTop > 100;
    };
    const changeDesign = index => {
      data.designId = index;
    };
    const changeStudy = index => {
      data.studyId = index;
    };
    const handleDesignUrl = item => {
      data.designUrl = item;
    };
    const handleStudyUrl = item => {
      data.studyUrl = item;
    };
    const getDesignUrl = () => {
      return require(`./images/success${data.designUrl}.png`);
    };
    const getStudyUrl = () => {
      return require(`./images/case${data.studyUrl}.png`);
    };
    const handleMenu = ind => {
      const domTop = document.getElementById(`content${ind}`).offsetTop;
      nextTick(() => {
        mainDom.value.scrollTo({
          behavior: 'smooth',
          top: domTop
        });
      });
    };
    return {
      ...toRefs(data),
      mainDom,
      handleScroll,
      changeDesign,
      handleDesignUrl,
      changeStudy,
      getStudyUrl,
      handleStudyUrl,
      getDesignUrl,
      handleMenu
    };
  }
};